<template>
  <div class="d-flex align-center justify-center">
    <div v-if="text" class="mx-2">
      <EmBody2 bold :class="textColor || 'text-neutral-lighten-2'">
        {{ text }}
      </EmBody2>
    </div>
    <v-progress-circular
      :color="color"
      :size="dimensions.size"
      :width="dimensions.width"
      indeterminate
      :data-cy="dataCyFromField"
    />
  </div>
</template>
<script setup>
const props = defineProps({
  text: { type: String, default: null },
  color: { type: String, default: 'primary' },
  size: { type: [Number, String], default: 32 },
  width: { type: [Number, String], default: 4 },
  small: { type: Boolean, default: false },
  large: { type: Boolean, default: false },
  dataCyFromField: { type: String, default: 'spinnerLoader' }
})
const dimensions = computed(() => {
  if (props.small) { return { size: 22, width: 2 } }
  if (props.large) { return { size: 96, width: 6 } }
  return { size: props.size, width: props.width }
})
const textColor = computed(() => {
  return `text-${props.color}`
})
</script>
